import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { ProductPageDTO } from '@DTO/ProductPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface ProductPageProps {
  pageContext?: TemplatePageContextData;
}

const ProductPage = ({ pageContext }: ProductPageProps) => {
  const productPage = ProductPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(productPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={productPage.metaData}
        breadcrumbs={productPage.breadcrumbs}
        stickedForm={productPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={productPage.langPath}
      >
        <PageComponents
          components={productPage.components}
          type="productDetail"
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default ProductPage;
