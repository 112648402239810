import get from 'lodash.get';

export const cardWithProductDetails = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    backgrond: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    firstCardSide: 'right',
    cards: [
      {
        headline: '',
        description: get(data, 'fieldCopy.processed', ''),
        image: get(data, 'fieldImage', undefined)
          ? {
            alt: get(data, 'fieldImage.alt', ''),
            title: get(data, 'fieldImage.title', ''),
            url: get(data, 'fieldImage.url', ''),
            webp: get(data, 'fieldImage.derivative.url'),
          }
          : undefined,
        video: get(data, 'fieldVideo.entity.url', undefined),
        videoUrl: get(data, 'fieldVideoUrl.url.path', undefined),
        imageOrVideo: get(data, 'fieldSelectImageOrVideo', 'image'), // return: 'image' | 'video'
        label: undefined,
        cta: undefined,
        ctaList: get(data, 'fieldLinkOrUpload', []).map((link) => {
          const linkData =
            get(link, 'entity.fieldLinkProduct', undefined) ||
            get(link, 'entity.fieldUploadProduct', undefined);

          return {
            label: get(linkData, 'title', undefined) || get(linkData, 'description', ''),
            url: get(linkData, 'url.path', undefined) || get(linkData, 'entity.url', '/'),
            target:
              get(link, 'entity.fieldSelectLinkOrUpload', 'link') === 'link'
                ? '_self'
                : '_blank',
          };
        }),
        poster: get(data, 'fieldPoster', undefined) ? {
          alt: get(data, 'fieldPoster.alt', ''),
          title: get(data, 'fieldPoster.title', ''),
          url: get(data, 'fieldPoster.url', ''),
          webp: get(data, 'fieldPoster.derivative.url'),
        } : undefined,
        schemaData: get(data, 'fieldName', undefined) ? {
          name: get(data, 'fieldName', ''),
          duration: get(data, 'fieldVideoDuration', ''),
          description: get(data, 'fieldVideoDescription', ''),
          uploadDate: get(data, 'entityCreated', '')
        } : undefined
      },
    ],
  }
}